import { render, staticRenderFns } from "./Person.vue?vue&type=template&id=32ca9ebb&scoped=true&"
import script from "./Person.vue?vue&type=script&lang=ts&"
export * from "./Person.vue?vue&type=script&lang=ts&"
import style0 from "./Person.vue?vue&type=style&index=0&id=32ca9ebb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ca9ebb",
  null
  
)

export default component.exports