
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";
import { personSave } from "@/api";
import _date from "@wedjs/date";

@Component
export default class Stay extends Vue {
    @Prop(Boolean) visible = false;
    @Prop([String, Number]) user_id!: any;
    @Prop([String, Number]) id!: any;
    @Prop(Object) data!: any;

    private form = {
        name: "",
        mobile: "",
        id_card: "",
        remark: "",
    };
    @Watch("data", { deep: true, immediate: true }) handler(data: any) {
        this.$set(this.form, "name", data.name || "");
        this.$set(this.form, "mobile", data.mobile || "");
        this.$set(this.form, "id_card", data.id_card || "");
        this.$set(this.form, "remark", data.remark || "");
    }

    get show(): boolean {
        return this.visible;
    }
    set show(v) {
        this.$emit("update:visible", false);
    }
    async onSubmit() {
        const form = this.id
            ? { ...this.form, user_id: this.user_id, id: this.id }
            : { ...this.form, user_id: this.user_id };
        await personSave(form);
        Toast.success(this.id ? "修改成功" : "添加成功");
        this.show = false;
        this.$emit("success");
    }
}
