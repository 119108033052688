
import { Component, Vue, Inject } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import { roomPerson, personDelete } from "@/api";
import PersonForm from "@/components/PersonForm.vue";

@Component({ components: { PersonForm } })
export default class Person extends Vue {
    private id = "";
    private list = [];
    private formVisible = false;
    private personId = null;
    private personData = {};

    created() {
        this.id = this.$route.params.id;
        this.roomPerson();
    }

    async deletePerson(id: string) {
        await Dialog.confirm({
            title: "提示",
            message: "确定删除该成员？",
        });
        await personDelete(id);
        Toast.success("删除成功");
        this.roomPerson();
    }
    async roomPerson() {
        const { data } = await roomPerson(this.id);
        this.list = data;
    }
}
